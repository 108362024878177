:root[data-theme="light"] {
  --text: #040316;
  --background: #faf8f5;
  --primary: #202020;
  --primary-dark: #484848;
  --secondary: #ffffff;
  --accent: #202020;
  --font-family: "Futura PT", serif;
}
:root[data-theme="dark"] {
  --text: #eae9fc;
  --background: black;
  --primary: #F5F5F5;
  --secondary: #1E1E1E;
  --secondary-light: #303030;
  --accent-light: #565656;
  --font-family: "Futura PT", serif;
}

body {
  background: var(--background);
  color: var(--text);
  font-family: var(--font-family);
}

.ant-select-dropdown {
  background-color: var(--secondary-light);
}

.ant-select .ant-select-arrow{
  color: rgba(255, 255, 255, 0.35);
}

.ant-select-single.ant-select-open .ant-select-selection-item{
  color: rgba(255, 255, 255, 0.85);
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-single.ant-select-open .ant-select-selection,
.ant-select-single.ant-select-open .ant-select-selection-item,
.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  border-color: var(--accent-light) !important;
  box-shadow: none !important;
}


.ant-checkbox .ant-checkbox-inner{
  background-color: var(--secondary);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent-light);
  border-color: var(--accent-light);
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--secondary);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  background-color: var(--secondary-light);
  border-color: var(--accent-light);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
  background-color: var(--accent-light);
  border-color: var(--accent-light);
}

.ant-checkbox-wrapper{
  color: #c4c4c4;
}

.ant-checkbox .ant-checkbox-inner{
  border-color: #7a7a7a;
}

.ant-input::placeholder {
  color: #767676;
}


.ep-action-btn {
  color: #1E1E1E;
  border-radius: 8px;
  padding: 10px 18px;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
  font-weight: 400;

  background-color: #F5F5F5;
  &:hover {
    background-color: #b5b5b5;
  }

  &.small {
    padding: 7px 10px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.transparent {
    color: black;
    @apply bg-transparent;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }
  }

  &.light{

    @apply bg-white text-primary border border-primary;

    &.inactive {
      cursor: default;
      @apply bg-opacity-50;
    }

    &:not(.inactive):hover {
      @apply bg-gray-100;
    }


  }

}


.input-group{
  label {
    margin-bottom: 3px;
    display: block;
  }
  .ant-checkbox-wrapper{
    display: flex;
    margin-bottom: 0px;
  }
}